<template>
  <product title="Amazfit 智能手表青春版"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="499"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://j.youzan.com/DnPjKh"
  ></product>
</template>

<script>
import { CDN_URL, RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'verge-lite',
  components: {
    Product
  },
  data () {
    return {
      slogan: 'AMOLED 炫彩屏 | 20 天续航 | GPS 记录多种运动模式<br/> 支付宝离线支付',
      colors: [
        '酷鲨灰',
        '雪山白'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/verge-lite/swiper/1.png',
          CDN_URL + '/images/product/verge-lite/swiper/2.png',
          CDN_URL + '/images/product/verge-lite/swiper/3.png',
          CDN_URL + '/images/product/verge-lite/swiper/4.png'
        ],
        [
          CDN_URL + '/images/product/verge-lite/swiper/5.png',
          CDN_URL + '/images/product/verge-lite/swiper/6.png',
          CDN_URL + '/images/product/verge-lite/swiper/7.png',
          CDN_URL + '/images/product/verge-lite/swiper/8.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        CDN_URL + '/images/product/verge-lite/detail/01w.jpg',
        CDN_URL + '/images/product/verge-lite/detail/02.jpg',
        CDN_URL + '/images/product/verge-lite/detail/03.jpg',
        CDN_URL + '/images/product/verge-lite/detail/04.jpg',
        CDN_URL + '/images/product/verge-lite/detail/05.jpg',
        CDN_URL + '/images/product/verge-lite/detail/06.jpg',
        CDN_URL + '/images/product/verge-lite/detail/07.jpg',
        CDN_URL + '/images/common/zepp-white.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/verge-lite/detail/08.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
